import { UserContextService } from 'src/app/core/service/user-context.service';
import { Component, ViewChild, Injector, ElementRef, AfterContentInit, inject, input, output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, FormsModule } from '@angular/forms';
import { ElementBase } from '../element-base';
import { GlobalConstants } from 'src/app/core/global-constants';
import uniqueId from 'lodash-es/uniqueId';
import { Subject } from 'rxjs';
import { FormControlContainerComponent } from '../form-control-container/form-control-container.component';
import { ResetDirective } from '../../directives/reset.directive';
import { NgClass } from '@angular/common';
import { FileFormatValidator } from '../validators/file-format-validator.directive';
import { ModalService } from 'src/app/core/service/modal.service';

@Component({
    selector: 'file-selector',
    templateUrl: './file-selector.html',
    styleUrls: ['./file-selector.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: FileSelectorComponent,
        multi: true
    }],
    imports: [FormControlContainerComponent, FormsModule, NgClass]
})
export class FileSelectorComponent extends ElementBase<File> implements AfterContentInit {
    element = inject(ElementRef);
    private modalService = inject(ModalService);

    public readonly label = input<string>();
    public readonly maxLength = input<number>(GlobalConstants.defaultMaxInputLength);
    public readonly removeLabel = input(false);
    public readonly reset = input<Subject<any>>();
    public readonly data = input<any>();
    public readonly btnText = input("Upload");

    public readonly fileUploaded = output<void>();

    @ViewChild(NgModel, { static: true }) public model: NgModel;

    public dataProp: any;
    public id: string = uniqueId('input_');
    public isRequired = false;
    public resetContext = inject(ResetDirective, { optional: true });
    public fileFormatContext = inject(FileFormatValidator, { optional: true });
    public accept: string;

    public constructor() {
        const injector = inject(Injector);
        const userContextService = inject(UserContextService);

        super(injector, userContextService);
    }

    public ngAfterContentInit(): void {
        this.dataProp = this.data();
        this.setAccept();
        if (this.reset() != null) {
            this.reset().subscribe(() => {
                this.dataProp = null;
            });
        }
        if (this.resetContext != null) {
            this.resetContext.reset().subscribe(() => {
                this.dataProp = null;
            });
        }
    }

    public setAccept(): void {
        if (this.accept) {
            return;
        }

        this.accept = GlobalConstants.acceptedFileTypes.join(',');
        const customAcceptedFileTypes = this.fileFormatContext?.customAcceptedFileTypes();
        if (customAcceptedFileTypes && customAcceptedFileTypes.length > 0) {
            this.accept = customAcceptedFileTypes.join(',')
        }

    }

    protected onControlInitialized() {
        if (this.control && this.element.nativeElement.hasAttribute('required')) {
            this.isRequired = true;
        }
    }

    public onFileSelected(file: File) {
        if (file.size < 10) {
            this.modalService.openInformationModal("File size smaller than 10 byte. Check the file and try again");
            return;
        }
        if (file.size > 20971520) {
            this.modalService.openInformationModal("File size should be less than 21MB to upload");
            return;
        }
        this.value = file;
        this.fileUploaded.emit();
    }
}
