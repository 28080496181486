<form-control-container [ngClass]="isDarkTheme ? 'dark-theme' : 'light-theme'" [hostNode]="element?.nativeElement"
  [control]="control">
  <div class="file-wrap">
    @if (label()) {
    <label class="file-wrap__label" [attr.for]="id">{{label()}}@if (isRequired) {
      <span style="color: red;">*</span>
      }</label>
    }
    @if(accept)
    {
    <input [accept]="accept" (change)="onFileSelected($event.target.files[0])" [(ngModel)]="dataProp"
      class="form-control file-wrap__input" type="file" [attr.id]="id" [attr.placeholder]="label()"
      [maxLength]="maxLength()" SkipControl />
    }
    <div class="file-wrap__btn btn-submit">{{btnText()}}</div>
    @if(!value?.name.includes("filePlaceholder") && value?.name != null && value?.name != "")
    {
    <span class="file-wrap__file-label">{{value?.name}}</span>
    <span class="file-wrap__file-label-mob">Photo attached</span>
    }
  </div>
</form-control-container>