import { Directive, input, OnInit } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { GlobalConstants } from 'src/app/core/global-constants';

@Directive({
    selector: '[FileFormat]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: FileFormatValidator, multi: true }
    ],
    standalone: true
})
export class FileFormatValidator implements Validator, OnInit {
    public readonly customAcceptedFileTypes = input<string[]>(undefined, { alias: "FileFormat" });
    private customAcceptedFileTypesProp: string[];
    private error: string;

    public ngOnInit(): void {
        this.customAcceptedFileTypesProp = this.customAcceptedFileTypes();
    }

    public validate(c: AbstractControl): { [key: string]: any } {
        return c.value && !this.validateFile(c.value)
            ? { fileInvalid: { message: this.error } }
            : undefined;
    }

    private validateFile(file: File): boolean {
        const fileType = file.type;

        if ((this.customAcceptedFileTypesProp as any) == "") {
            this.customAcceptedFileTypesProp = null;
        }

        let extensionInvalid = GlobalConstants.acceptedFileTypes.some(x => x == fileType);
        const customAcceptedFileTypes = this.customAcceptedFileTypesProp;
        if (customAcceptedFileTypes) {
            extensionInvalid = customAcceptedFileTypes.some(x => x == fileType);
        }
        const sizeInvalid = file.size < GlobalConstants.maxFileSizeMb * GlobalConstants.oneMb;

        const result = extensionInvalid && sizeInvalid;
        if (!result) {
            const acceptedFileTypes = customAcceptedFileTypes ?? GlobalConstants.acceptedFileTypes;
            this.error = !extensionInvalid ?
                `Please upload ${acceptedFileTypes.map(x => x.replace('image/', '').replace('application/', '')).join(',')} file` :
                `File size should be less than ${GlobalConstants.maxFileSizeMb} MB`;
        }
        return result;
    };
}
